import React from 'react';
import Layout from '../components/layout';
import HeroBlock from '../components/blocks/hero';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import obj1 from '../images/elements/about-obj-1.png';
import obj2 from '../images/elements/about-obj-2.png';
import playerImg from '../images/elements/about-player.png';
import splashImg from '../images/elements/about-phone.png';

export default class HomePage extends React.Component {
    render() {
        return (
            <Layout>
                <HeroBlock />

                {/** About Section */}
                <section className="pt-120 pb-120 position-relative overflow-hidden">
                    <div className="about-obj-1"><img src={obj1} alt="about-img" /></div>
                    <div className="about-obj-2"><img src={obj2} alt="about-img" /></div>
                    <Container>
                        <Row>
                            <Col lg={8}>
                                <div className="about-content">
                                    <div className="section-header has--bg">
                                        <h2 className="section-title">We Focus on Quality Entertainment</h2>
                                    </div>
                                    <p>We are a very talented independent team of game and arts developers who are passionate about games and everything related to it including arts, animations and film. We place key emphasis on creating projects that are of a high quality in every way possible and this is how things roll in BadArts Studio.</p>
                                    <Link to="/about" className="cmn-btn mt-5">learn more</Link>
                                </div>
                            </Col>
                            <Col lg={4} className="mt-lg-0 mt-4">
                                <div className="about-thumb">
                                    <img src={playerImg} alt="about-img" className="image-1" />
                                    <img src={splashImg} alt="about-img" className="image-2" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        )
    }
}