import React from 'react';
import HeaderLayout from './header';
import FooterLayout from './footer';
import { fixedMenu, mobileMenu, preloader, replaceBackground } from "../../main";

export interface LayoutProps {
    children: React.ReactNode
}

export default class Layout extends React.Component<LayoutProps> {
    componentDidMount() {
        preloader();
        replaceBackground();

        // menu options custom affix
        fixedMenu();

        // mobile menu js
        mobileMenu();
    }

    render() {
        return (
            <>
                {/** Preloader */}
                <div className="preloader">
                    <div className="spinner">
                        <div className="dot1"/>
                        <div className="dot2"/>
                    </div>
                </div>

                {/** Scroll to Top */}
                <div className="scroll-to-top">
                    <span className="scroll-icon">
                        <i className="las la-arrow-up"/>
                    </span>
                </div>

                {/** Layout Content */}
                <div className="page-wrapper">
                    <HeaderLayout />
                    <React.Fragment>{this.props.children}</React.Fragment>
                    <FooterLayout />
                </div>
            </>
        )
    }
}