import React from 'react';
import { Col, Container, Row } from "react-bootstrap";

import bgImg from '../images/bg/thanks.jpg';

const DonationPage: React.FC = () => {
    return (
        <section className="vh-100">
            <Container className="h-100">
                <Row className="justify-content-center h-100 align-items-center">
                    <Col className="col-lg-8">
                        <div className="d-flex justify-content-center"><img src={bgImg} alt="donation" /></div>
                        <div className="section-header text-center mt-3 has--bg">
                            <h2>AWESOME!, much appreciated. This will help me get the new laptop ASAP.</h2>
                            <a href="/#" className="cmn-btn style--two mt-5">
                                <span className="d-flex align-items-center">
							        <i className="fab fa-3x fa-discord pr-2" aria-hidden="true"/>
							        Join Our Discord
					            </span>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default DonationPage;
