import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import heroImg from '../../images/bg/hero.jpg';
import adImg from '../../images/donation2.gif';
import shapeImg from '../../images/elements/hero/shape-2.png';

const HeroBlock: React.FC = () => {
    return (
        <section className="hero bg_img" data-background={heroImg}>
            <div className="hero__shape"><img src={shapeImg} alt="hero-bg" /></div>
            <Container>
                <Row>
                    <Col lg={7} sm={12}>
                        <div className="hero__content">
                            <span className="hero__sub-title">BadArts Studios</span>
                            <h2 className="hero__title">GAMES & ARTS</h2>
                            <p>We've been creating innovative games and arts since 2019. We offer our clients professional development services.</p>
                            <Link to="/games" className="cmn-btn mb-4">play now</Link>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <a href="https://paystack.com/pay/cyclone"><Image src={adImg} style={{ width: '700px' }} /></a>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HeroBlock;
