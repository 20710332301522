import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomePage from './pages/home.page';
import DevPage from './pages/dev.page';
import DonationPage from "./pages/donation.page";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/thanks" component={DonationPage} />
        <Route path="*" component={DevPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default App;
