import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import footerImg from '../../images/elements/footer.png';

type FooterLinks = {
    title: string,
    links: {
        link: string,
        text: string,
    }[]
}

const Footer: FooterLinks[] = [
    {
        title: "ABOUT",
        links: [
            {
                link: "/about",
                text: "About Us",
            },
            {
                link: "/contact",
                text: "Contact Us",
            },
            {
                link: "/support",
                text: "Support Us",
            },
        ],
    },
    {
        title: "MY ACCOUNT",
        links: [
            {
                link: "/account",
                text: "Manage Your Account",
            },
            {
                link: "/partner",
                text: "Become A Partner",
            },
            {
                link: "/contact",
                text: "Request A Project",
            },
        ],
    },
    {
        title: "HELP CENTER",
        links: [
            {
                link: "/help",
                text: "Help Center",
            },
            {
                link: "/faq",
                text: "FAQ",
            },
            {
                link: "/tutorials",
                text: "Tutorials",
            },
        ],
    },
    {
        title: "LEGAL INFO",
        links: [
            {
                link: "/terms-and-conditions",
                text: "Privacy Policy",
            },
            {
                link: "/terms-of-service",
                text: "Terms of Service",
            },
            {
                link: "/partnership",
                text: "Partnership Agreement",
            },
        ],
    },
];

const FooterLayout: React.FC = () => {
    return (
        <footer className="footer-section bg_img" data-background={footerImg}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="subscribe-area">
                            <div className="subscribe-content">
                                <h2 className="title">Exclusive Updates From Our Studio!</h2>
                                <p>Subscribe to get updated on future games and arts releases</p>
                                <form className="subscribe-form">
                                    <input type="email" name="subscribe_email" id="subscribe_email" placeholder="Enter Your Email" />
                                    <button type="submit">Subscribe</button>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="footer-top mb-none-50">
                    {Footer.map((footer, i) => <Col lg={3} sm={6} key={i} className="mb-50">
                    <div className="footer-widget">
                            <h3 className="footer-widget__title">{footer.title}</h3>
                            <ul className="short-links">
                                {footer.links.map((short, i) => <li key={i}><a href={short.link}>{short.text}</a></li>)}
                            </ul>
                        </div>
                    </Col>)}
                </Row>
                <div className="row footer-bottom align-items-center">
                    <div className="col-lg-7 col-md-6 text-md-left text-center">
                        <p className="copy-right-text">Copyright © 2021.All Rights Reserved By <a href="https://biurad.com">Biurad Lap</a></p>
                    </div>
                    <div className="col-lg-5 col-md-6 mt-md-0 mt-3">
                        <ul className="social-links justify-content-md-end justify-content-center">
                            <li><a href="/#"><i className="lab la-facebook-f"/></a></li>
                            <li><a href="/#"><i className="lab la-twitter"/></a></li>
                            <li><a href="/#"><i className="lab la-discord"/></a></li>
                            <li><a href="/#"><i className="lab la-linkedin"/></a></li>
                            <li><a href="/#"><i className="lab la-instagram"/></a></li>
                        </ul>
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default FooterLayout;
