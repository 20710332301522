import React, {useEffect} from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { replaceBackground } from "../main";

import bgImg from '../images/bg/games-details.jpg';

const DevPage: React.FC = () => {
    useEffect(replaceBackground, []);

    return (
        <section className="vh-100 bg_img" data-background={bgImg}>
            <Container className="h-100">
                <Row className="justify-content-center h-100 align-items-center">
                    <Col className="col-lg-8">
                        <div className="section-header text-center has--bg">
                            <h2 className="section-title text-danger">This Page Is On Active Development</h2>
                            <h4>Our Discord channel is the number one spot to get new and updated information about
                                BadArts Studio, speak with fellow Members, as well as having discussions with other team
                                mates.</h4>
                            <a href="/#" className="cmn-btn style--two mt-5">
                                <span className="d-flex align-items-center">
							        <i className="fab fa-3x fa-discord pr-2" aria-hidden="true"/>
							        Join Our Discord
					            </span>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default DevPage;
