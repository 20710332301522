import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import { Link } from 'react-router-dom';
import logoImg from '../../images/logo.png';

const HeaderLayout: React.FC = () => {
    return (
        <header className="header">
            <div className="header__bottom">
                <Container>
                    <Navbar expand="xl" className="align-items-center">
                        <a className="site-logo site-title" href="./">
                            <img src={logoImg} alt="site-logo" />
                            <span className="logo-icon"><i className="flaticon-fire" /></span>
                        </a>
                        <NavbarToggle data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                            <span className="menu-toggle"/>
                        </NavbarToggle>
                        <NavbarCollapse id="navbarSupportedContent">
                            <Nav className="ml-auto main-menu" as="ul">
                                <Nav.Item as="li" bsPrefix="menu_has_children">
                                    <Link to="/#">Arts</Link>
                                    <ul className="sub-menu">
                                        <li><Link to="/arts">Graphic Arts</Link></li>
                                        <li><Link to="/animations">Animations</Link></li>
                                    </ul>
                                </Nav.Item>
                                <Nav.Item as="li" bsPrefix="menu_has_children">
                                    <Link to="/#">Games</Link>
                                    <ul className="sub-menu">
                                        <li><Link to="/games">All Games</Link></li>
                                        <li><Link to="/games/upcoming">Upcoming Games</Link></li>
                                        <li><Link to="/games/released">Released Games</Link></li>
                                    </ul>
                                </Nav.Item>
                                <li><Link to="/events">Events</Link></li>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/contact">contact</Link></li>
                            </Nav>
                            <div className="nav-right">
                                <a href="/#" data-toggle="modal" data-target="#loginModal">join now</a>
                            </div>
                        </NavbarCollapse>
                    </Navbar>
                </Container>
            </div>
        </header >
    )
}

export default HeaderLayout;
