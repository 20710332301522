import $ from 'jquery';

function preloader() {
    const preloader = $(".preloader");

    preloader.delay(300).animate(
        {"opacity": 0},
        800,
        () => preloader.css("display", "none")
    );

    // Animate the scroll to top
    $(".scroll-to-top").on("click", function (event): void {
        event.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 300);
    });
}

function fixedMenu() {
    $(window).on("scroll", function (): void {
        const scrollTop = $(this).scrollTop() ?? 0;

        if (scrollTop > 200) {
            $(".scroll-to-top").fadeIn(200);
        } else if (scrollTop > 50) {
            $(".header").addClass("menu-fixed");
        } else {
            $(".header").removeClass("menu-fixed");
            $(".scroll-to-top").fadeOut(200);
        }
    });
}

function mobileMenu() {
    $('.navbar-toggler').on('click', function (): void {
        $('.navbar-collapse').collapse('hide');
    });

    $(".navbar-collapse>ul>li>a, .navbar-collapse ul.sub-menu>li>a").on("click", function (): void {
        const element = $(this).parent("li");

        if (element.hasClass("open")) {
            element.removeClass("open");
            element.find("li").removeClass("open");
        } else {
            element.addClass("open");
            element.siblings("li").removeClass("open");
            element.siblings("li").find("li").removeClass("open");
        }
    });
}

/**
 * Add a background-image to elements having a `bg_img` class
 * and a `data-background` attribute.
 */
function replaceBackground() {
    $('.bg_img').css('background-image', function (): string {
        return ('url(' + $(this).data('background') + ')');
    });
}

export { preloader, replaceBackground, fixedMenu, mobileMenu };
